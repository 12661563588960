@import '~antd/dist/antd.css';

.header {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 16px;
    float: right;
    background-color: #161616;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    color: #fff;
    flex-direction: row-reverse;
  }
  
  .user-avatar {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .user-name {
    margin-left: 8px;
  }
  