
.container {
    /* max-width: 800px; */
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
 .card {
     /* max-width: 800px; */
     margin: 5 auto;
     padding: 15px;
     background-color: #f9f9f9;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
     border-radius: 8px;
 }
.form-container {
    flex: 1;
    margin-right: 20px;
}

.items-container {
    width: 200px;
}

.title {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

label {
    margin-bottom: 5px;
    display: block;
    color: #333;
}

.input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
    transition: border-color 0.3s;
}

.input:focus {
    border-color: #007bff;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:disabled {
    background-color: #cccccc;
}

.button:hover:enabled {
    background-color: #0056b3;
}

.error {
    color: red;
    text-align: center;
}

.results {
    list-style: none;
    padding: 0;
}

.result-item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    background-color: #fff;
}

.no-data {
    text-align: center;
    color: #555;
}

.item-list {
    list-style: none;
    padding: 0;
}

.item {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.item:hover {
    background-color: #e0e0e0;
}

.selected {
    background-color: #007bff;
    color: #fff;
}
.items-container {
    width: 300px;
    margin-left: 20px;
}

.item-list {
    list-style: none;
    padding: 0;
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.coupon-text {
    margin-left: 8px;
}

.assign-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.assign-button:hover {
    background-color: #0056b3;
} 