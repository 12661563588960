.dashboard-container {
    padding: 16px;
  }
  
  .dashboard-tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .dashboard-tile {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .unique-button-container {
    text-align: center;
    margin-top: 24px;
  }
  